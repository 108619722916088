import PropTypes from 'prop-types'

import {
  BADGE_COLOR_BLACK,
  BADGE_COLOR_GRADIENT,
  BADGE_COLOR_GREEN,
  BADGE_COLOR_GREY,
  BADGE_COLOR_PINK,
  BADGE_COLOR_YELLOW,
} from '../../../lib/constants'
import styles from './badge.module.scss'

export const Badge = ({ label = '', color = BADGE_COLOR_GREY, className = '' }) => {
  return (
    <span className={`${styles.badge} ${styles['badge--' + color]} ${className}`}>{label}</span>
  )
}

Badge.defaultProps = {
  label: '',
  color: BADGE_COLOR_GREY,
  className: '',
}

Badge.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    BADGE_COLOR_PINK,
    BADGE_COLOR_GREEN,
    BADGE_COLOR_YELLOW,
    BADGE_COLOR_GREY,
    BADGE_COLOR_BLACK,
    BADGE_COLOR_GRADIENT,
  ]),
  className: PropTypes.string,
}
