import { useEffect, useState } from 'react'

import styles from './loco-score.module.scss'

type LocoScoreProps = {
  className?: string
  rating: number
  iconOnly?: boolean
  isInline?: boolean
}

export const LocoScore = ({
  rating,
  className = '',
  iconOnly = false,
  isInline = false,
}: LocoScoreProps) => {
  const [strokeDasharray, setStrokeDasharray] = useState('0, 100')
  const [colour, setColour] = useState('#FFCC00')
  const [currentRating, setCurrentRating] = useState(0)
  const PERFECT_SCORE = 73

  useEffect(() => {
    // Setting up the dynamic keyframe end value when the component mounts or `rating` changes
    if (currentRating !== rating) {
      const animationCSS = `${(rating / 100) * PERFECT_SCORE}, 100`
      const animation = setTimeout(() => {
        setStrokeDasharray(animationCSS)
        setColour(rating >= 80 ? '#FF2D55' : rating > 70 ? '#F15A24' : '#FFCC00')
        setCurrentRating(rating)
      }, 5)
      return () => {
        clearTimeout(animation)
      }
    }
  }, [currentRating, rating])

  const getDescriptionFromRating = () => {
    if (rating >= 90) {
      return 'Amazing value'
    } else if (rating >= 80) {
      return 'Great value'
    } else if (rating >= 70) {
      return 'Fair value'
    } else {
      return 'Low value'
    }
  }

  return (
    <div
      className={`${styles.container} ${className}`}
      data-is-inline={isInline}
      data-icon-only={iconOnly}>
      <span
        style={{
          position: 'relative',
          display: 'grid',
          placeItems: 'center',
          height: 34,
          width: 34,
        }}>
        <svg
          width="34"
          height="30"
          viewBox="0 0 34 30"
          fill="none"
          className={styles.ring}
          style={{
            position: 'absolute',
            inset: 0,
            zIndex: 5,
          }}
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.5 28C3.60451 25.1157 1.5 21.5 1.5 17C1.5 9 8.78728 1.5 17 1.5C25.2127 1.5 32.2701 8.94483 32.4028 17.3729C32.3374 21.3134 30.7773 24.992 27.5 28"
            stroke={colour}
            style={{
              strokeDasharray,
            }}
            strokeLinecap="round"
            strokeWidth="3"
          />
        </svg>
        <svg
          width="34"
          height="30"
          viewBox="0 0 34 30"
          fill="none"
          style={{
            position: 'absolute',
            inset: 0,
            zIndex: 0,
          }}
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.5 28C3.60451 25.1157 1.5 21.5 1.5 17C1.5 9 8.78728 1.5 17 1.5C25.2127 1.5 32.2701 8.94483 32.4028 17.3729C32.3374 21.3134 30.7773 24.992 27.5 28"
            stroke="#E5E5EA"
            strokeLinecap="round"
            strokeWidth="3"
          />
        </svg>
        <span
          className={styles.score}
          style={{
            color: colour,
          }}>
          {rating}
        </span>
        {rating > 90 && (
          <svg
            width="14"
            height="13"
            viewBox="0 0 14 13"
            fill="none"
            className={styles.flame}
            style={{
              position: 'absolute',
              bottom: 0,
            }}
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.19652 8.10588C5.53809 8.10588 5.86567 7.9702 6.10719 7.72867C6.34872 7.48715 6.4844 7.15957 6.4844 6.818C6.4844 6.1071 6.22683 5.7877 5.96925 5.27255C5.41701 4.16858 5.85386 3.18413 6.99955 2.18164C7.25713 3.46952 8.02986 4.70588 9.06016 5.53013C10.0905 6.35437 10.6056 7.33316 10.6056 8.36346C10.6056 8.83701 10.5123 9.30593 10.3311 9.74344C10.1499 10.1809 9.88428 10.5785 9.54942 10.9133C9.21457 11.2482 8.81704 11.5138 8.37953 11.695C7.94203 11.8762 7.47311 11.9695 6.99955 11.9695C6.526 11.9695 6.05708 11.8762 5.61957 11.695C5.18207 11.5138 4.78454 11.2482 4.44968 10.9133C4.11483 10.5785 3.84921 10.1809 3.66799 9.74344C3.48677 9.30593 3.39349 8.83701 3.39349 8.36346C3.39349 7.76949 3.61655 7.1817 3.90865 6.818C3.90865 7.15957 4.04433 7.48715 4.28586 7.72867C4.52738 7.9702 4.85496 8.10588 5.19652 8.10588Z"
              fill="#FF2D55"
            />
          </svg>
        )}
      </span>
      {!iconOnly && (
        <div className={styles.text}>
          <p className={styles.title}>LocoScore</p>
          <p className={styles.description}>{getDescriptionFromRating()}</p>
        </div>
      )}
    </div>
  )
}
