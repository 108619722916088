import Image from 'next/image'
import { useMediaQuery } from 'react-responsive'
import Snowfall from 'react-snowfall'

import styles from './hero-background.module.scss'
import { TrustpilotWidget } from '@/components/styleguide/TrustpilotWidget'
import { constants } from '@/lib/constants'

export const HeroBackground = ({ children, isVans }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' })

  return (
    <>
      <div className={`${styles.background} ${isVans ? styles['is-vans'] : styles['is-cars']}`}>
        <div className={styles.top}>
          <Image
            src={`${constants.imageHost.assets}/homepage/top-dash.svg`}
            alt="Top of a dashboard"
            layout="fill"
            priority
          />
        </div>
        {children}
        <div className={styles.bottom}>
          <Image
            src={`${constants.imageHost.assets}/homepage/bottom-dash.svg`}
            alt="Bottom of a dashboard"
            layout="fill"
            priority
          />
        </div>
        <Snowfall
          color="white"
          wind={[-2, 2.0]}
          snowflakeCount={isMobile ? 50 : 200}
          style={{ zIndex: -1 }}
        />
      </div>
      <div className={styles.widget}>
        <TrustpilotWidget
          link={`${constants.trustpilot.reviewUrl}/${constants.trustpilot.link}`}
          type="inline"
          rating={constants.trustpilot.score}
        />
      </div>
    </>
  )
}
