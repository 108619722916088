import Link from 'next/link'
import PropTypes from 'prop-types'
import { Fade } from 'react-awesome-reveal'
import { useMediaQuery } from 'react-responsive'

import { Badge } from '../Badge'
import styles from './vehicle-card.module.scss'
import { LocoScore } from '@/components/styleguide/LocoScore'
import { VehicleImage } from '@/components/styleguide/VehicleImage'
import { FILTER_STOCK_STATUS_IN_STOCK, FILTER_VEHICLE_TYPES_VANS } from '@/lib/constants'
import { useExperiment } from '@/lib/hooks/useExperiment'
import { addVatToPrice, formatPrice } from '@/lib/utilities/search'
import { addToDataLayer, generateVehicleLink } from '@/lib/utilities/vehicle'

export const VehicleCard = ({ deal, hasBorder = false }) => {
  const isVans = deal.vehiclePrice.vehicleType === FILTER_VEHICLE_TYPES_VANS
  const configUrlPath = generateVehicleLink(deal.vehiclePrice, 'config')
  const [experimentGroup] = useExperiment()
  const isMobile = useMediaQuery({ maxWidth: 991 })

  const onClickConfigButton = () => {
    addToDataLayer({
      event: 'homepage-click',
      action: 'config-click',
      experimentGroup,
    })
  }

  return (
    <div className={`${styles.card} ${hasBorder ? styles.border : ''}`}>
      <div className={styles.vehicle}>
        <div className={styles.text}>
          <p className={styles.tag}>New</p>
          <h3>{deal.vehicle.manufacturerName}</h3>
          <p>{deal.vehicle.modelName}</p>
        </div>
        <LocoScore
          rating={deal.vehiclePrice.rating}
          iconOnly={isMobile}
        />
      </div>
      <div className={styles.badges}>
        <Badge
          label={deal.vehiclePrice.bodyStyleName}
          className={styles.badge}
        />
        {deal.vehiclePrice.stockStatus === FILTER_STOCK_STATUS_IN_STOCK && (
          <Badge
            label={deal.vehiclePrice.stockStatusName}
            className={styles.badge}
          />
        )}
      </div>
      <Link href={configUrlPath}>
        <a className={styles.image}>
          <Fade triggerOnce>
            <VehicleImage
              imagePath={
                deal.vehicle.gallery?.images?.filter(img => img.angle === 'sideview')[0].path
              }
              backupImagePath={deal.vehicle.images.side}
              alt={`${deal.vehicle.manufacturerName} ${deal.vehicle.modelName}`}
              height={234}
              width={352}
            />
          </Fade>
        </a>
      </Link>
      <div className={styles.price}>
        <p>
          {formatPrice(
            isVans
              ? deal.vehiclePrice.monthlyPayment.toFixed(2)
              : addVatToPrice(deal.vehiclePrice.monthlyPayment),
          )}
          /pm{isVans ? ' ex. VAT' : ''}
        </p>
        <div className={styles['button-container']}>
          <Link href={configUrlPath}>
            <a
              className={styles.button}
              onClick={onClickConfigButton}>
              Configure<span>{isVans ? ' van' : ' car'}</span>
            </a>
          </Link>
        </div>
      </div>
    </div>
  )
}

VehicleCard.propTypes = {
  deal: PropTypes.object.isRequired,
}
