import dynamic from 'next/dynamic'
import Image from 'next/image'
import { useEffect } from 'react'
import { Fade } from 'react-awesome-reveal'
import { useDispatch } from 'react-redux'

import styles from './search.module.scss'
import { TrustpilotWidget } from '@/components/styleguide/TrustpilotWidget'
import { constants } from '@/lib/constants'
import { resetSearchState } from '@/store/reducers/search'
import { Theme } from '@/types/system'

const Animation = dynamic(() => import('../Animation'), { ssr: false })

export const Search = ({ isVans, children }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(resetSearchState())
  }, [dispatch])

  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          <h1>Your {isVans ? 'van' : 'car'} leasing compadre</h1>
          <h2>Compare millions of deals from over 80 providers.</h2>
        </div>
        <Fade triggerOnce>{children}</Fade>
      </div>
      <div className={styles['rear-mirror']}>
        <Image
          src={`${constants.imageHost.assets}/homepage/rear-mirror.svg`}
          height="148"
          width="349"
          alt="rear view mirror"
          priority
        />
      </div>
      <Fade
        triggerOnce
        className={styles.animation}>
        <Animation />
      </Fade>
      <div className={styles.extras}>
        <div className={styles.widget}>
          <TrustpilotWidget
            link={`${constants.trustpilot.reviewUrl}/${constants.trustpilot.link}`}
            type="inline"
            rating={constants.trustpilot.score}
            theme={Theme.dark}
          />
        </div>
      </div>
      <div className={styles.bottom}>
        <Image
          src={`${constants.imageHost.assets}/homepage/bottom-dash.svg`}
          alt="Bottom of a dashboard"
          layout="fill"
          priority
        />
      </div>
    </section>
  )
}
